import React, { useState, useEffect } from "react"
import YouTube from "react-youtube"
import Img from "gatsby-image"
import styles from "./feedback.module.scss"
import 'swiper/swiper.scss'
import YoutubeVideo from "../youtubeVideo/youtubeVideo"

const FeedbackItem = ({videoId, image, name, quote}) => {
  const [videoHidden, setVideoHidden] = useState(true)
  const [videoShown, setVideoShown] = useState(false)
  const [videoTime, setVideoTime] = useState(0)

  const renderVideo = () => {
    let opts = {
      width: '100%',
      className: 'video',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        color: 'white',
        autoplay: 1,
        rel: 0,
        start: videoTime,
      }
    };

    return <YouTube videoId={videoId}
                    opts={opts}
                    onPlay={() => videoPlayHandler()}
                    onEnd={() => videoEndHandler()}
                    onReady={(event) => videoReadyHandler(event)} />
  }

  const videoPlayHandler = () => {
    setVideoShown(false)
    setVideoHidden(false)
  }

  const videoEndHandler = () => {
    setVideoShown(true)
    setVideoHidden(true)
    setVideoTime(0)
  }

  const videoReadyHandler = (event) => {
    event.target.mute()
    event.target.playVideo()
    event.target.unMute()
  }

  const renderPlayText = () => {
    let text = 'play';
    videoShown && (text = 'play again')

    return text
  }

  return (
    <>
      <div className={`${styles.itemVideoDescriptionWrapper}`}>
        <span className={styles.quoteSymbol}>“</span>
        <h3 className={styles.quoteName}>{name}</h3>
        <p className={styles.quote}>{quote}</p>
      </div>
      <YoutubeVideo videoId={videoId} image={image} />
    </>
  )
}

export default FeedbackItem
