import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import useViewport from "../useViewport"
import styles from "./workflow.module.scss"
import 'swiper/swiper.scss';

import ContactButton from "../contactButton"

SwiperCore.use([Navigation, Pagination]);

const Workflow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { width } = useViewport()

  const data = [
    {
      "title": "Your account analysis. ",
      "content": "Grant viewer access to your Facebook & Google Ads Managers and Google Analytics - we’ll tell you the good, the bad, and the could be better. Actionable suggestions included."
    },
    {
      "title": "E-commerce strategist consultation.",
      "content": "Discuss business goals, processes, and the trends that you could utilise for scaling. We’ll run over S.01’s analysis and notes for our plan. If all sounds good, we’ll start working together."
    },
    {
      "title": "Competitor & market research.",
      "content": "Knowledge matters, so before we start advertising for you, we run an in-depth research. This helps us understand exactly what the customer is wishing for, and sell on that when we launch the ads."
    },
    {
      "title": "Trial & testing.",
      "content": "This is the big launch. We run ads, test audiences, adsets, creatives, adjust, and test anew. Eventually, we find what works best."
    },
    {
      "title": "Either a yes or a no.",
      "content": "We run over the results and evaluate the dynamics between us & you. If all parties are happy, we develop a strategy for further work."
    },
    {
      "title": "Omni channel presence established.",
      "content": "We plan out and implement an omni channel marketing strategy that covers all advertising mediums relevant for your business."
    },
    {
      "title": "Execution & making bank. 💸",
      "content": "We execute the plan, adapt quickly, and scale you all the way to Mount Olympus. We visit the bank on the way to celebrate your wins, too."
    },
  ];
    
  const renderCustomBullets = (index, className) => {
    
    const startingZero = (index < 9) && '0'
    let number;
    
    if (index < data.length - 1) {
      number = `<span class="number ${styles.number}">s.${startingZero}${index+1}</span>`
    }

    if (index === data.length - 1) {
      number = `<span class="number ${styles.number}">💸</span>`
    }
    
    const title = `<h4 class="title">${data[index].title}</h4>`
    
    return (
      '<div class="' + className + ' item-bullet">' + number + title + '</div>'
    )
  }
      
  return (
    <section id="workflow" className={styles.workflow}>
      <div className="container">
        <div className={styles.sectionWrapper}>
          <div className={styles.mainDescription}>
            <h2 className={`section-header ${styles.sectionHeader}`}>A workflow that delivers on your KPIs.</h2>
            <p className={styles.content}>Our approach lets us understand your brand, target audience, and the key issues that your product solves. You can call it our 6-step process for advertising and scaling brands to 7 figures and beyond.</p>
            {width > 450 && <ContactButton classes="outline" title="Meet Hades" /> }
          </div>
          {data &&
            <div className={styles.content}>
              <span id="pagination"></span>

              <Swiper
                slidesPerView={1}
                navigation
                pagination={{
                  el: '#pagination',
                  type: 'bullets',
                  clickable: true,
                  renderBullet: (index, className) => renderCustomBullets(index, className)
                }}
                onSlideChange={(swiper) => {setCurrentSlide(swiper.activeIndex); swiper.updateProgress()}}
                className={`${styles.slidesWrapper} ${(currentSlide >= data.length - 1) && styles.lastSlide + ' last-slide'}`}
              >
                {data.map((item, index) => (

                  <SwiperSlide key={index} className={styles.item}>                
                    <div className={styles.content}>
                      <span className={styles.number}>s.{index < 9 ? `0${index+1}` : index+1}</span>
                      <h3 className={styles.header}>{item.title}</h3>
                      <p className={styles.description}>{item.content}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          }
          {width <= 450 && <ContactButton classes="outline" title="Meet Hades" /> }
        </div>
      </div>
    </section>
  )
}

export default Workflow