import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { gsap, TimelineLite } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import styles from "./marketingChannelSection.module.scss"
import useViewport from "../useViewport"
import rocketLaunchGif from "../../images/marketingChannelSectionImages/rocket-launch.webp"
import lookingGif from "../../images/marketingChannelSectionImages/ace-looking.webp"
import babyThrowingMoneyGif from "../../images/marketingChannelSectionImages/baby-throwing-money.webp"
import Img from "gatsby-image"

gsap.registerPlugin(ScrollTrigger, TimelineLite);

const MarketingChannelSection = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file( relativePath: { eq: "marketingChannelSectionImages/head-hade-money.webp" } ) {
        id
        base
        childImageSharp {
          fixed(height: 250, width: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const { width } = useViewport()
  let marketingChannelsWrapper = useRef(null);

  useEffect(() => {
    const marketingChannels = marketingChannelsWrapper.current.querySelectorAll('.marketing-channel')
    const sectionHeader = marketingChannelsWrapper.current.querySelector('.section-header')
    const factArea = marketingChannelsWrapper.current.querySelector('.fact')
    const factAreaHeader = factArea.querySelector('.area-header')
    const factAreaContent = factArea.querySelector('.content-paragraph')

    gsap.from(sectionHeader, {
      scrollTrigger: {
        trigger: sectionHeader,
        toggleActions: 'play none none none',
        start: 'top 80%',
      },
      opacity: 0,
      duration: 1.5,
      y: 50,
      ease: "power2.out"
    })

    let factTl = new TimelineLite ({
      scrollTrigger: {
        trigger: factArea,
        toggleActions: 'play none none none',
        start: 'top 80%',
      }
    });

    factTl.fromTo(factArea, 1.5,
      {
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        webkitClipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
      },
      {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "expo.out"
      })
      .from(factAreaHeader, .5, { opacity: 0 }, "-=1")
      .from(factAreaContent, .5, { y: 10, opacity: 0 }, "-=.75")

    
    gsap.utils.toArray(marketingChannels).map((channel, index) => {
      let slideLeftPathStart = 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)';

      if ( width < 770 ) {
        slideLeftPathStart = (index % 2 === 0) ? 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)' : 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)';
      }
      
      if ( width < 451 ) {
        slideLeftPathStart = 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)';
      }

      const imageWrapper = channel.querySelector('.image-wrapper')
      const image = imageWrapper.querySelector('.marketing-channel-image')
      const header = channel.querySelector('.area-header')
      const headerArrow = header.querySelector('.arrow-wrapper')
      const headerText = header.querySelector('.header-text')
      const channelDescription = channel.querySelector('.channel-description')

      let tl = new TimelineLite ({
        scrollTrigger: {
          trigger: channel,
          toggleActions: 'play none none none',
          start: 'top 80%',
        }
      });

      tl.fromTo(imageWrapper, 1.5,
          {
            clipPath: slideLeftPathStart,
            webkitClipPath: slideLeftPathStart
          },
          {
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            ease: "expo.out"
          }
        )
        .from(image, .75, { scale: 1.5 }, "-=1.5")
        .from(headerArrow, .75, { opacity: 0, x: -20, ease: "back.out(2)" }, "-=.9")
        .from(headerText, 1, { x: -headerText.offsetWidth }, "-=.5")
        .from(channelDescription, 1, { opacity: 0, y: 30, ease: "expo.out" }, "-=.5")
    })
  }, [marketingChannelsWrapper])

  return (
    <section id="services" className={styles.marketingChannelSection}>
      <div className="container">
        <div ref={marketingChannelsWrapper} className={styles.marketingChannelSectionWrapper}>
          <h2 className={`section-header ${styles.sectionHeader}`}>Others call it an omni channel approach. We call it marketing run by Hades.</h2>
          <div className={`fact ${styles.fact}`}>
            <h3 className={`area-header ${styles.header}`}>Fact:</h3>
            <p className={`content-paragraph ${styles.contentParagraph}`}>We’ve scaled multiple businesses from 5 to 7 figures.</p>
          </div>
          <div className={`marketing-channel ${styles.marketingChannel}`}>
            <div className={`image-wrapper ${styles.imageWrapper}`}>
              <div className={`image-overflow ${styles.imageOverflow}`}></div>
              <img className={`marketing-channel-image ${styles.marketingChannelImage}`} src={rocketLaunchGif} alt="Rocket Launching" />
            </div>
            <div className={`${styles.content}`}>
              <h3 className={`area-header arrow-header arrow-header-1 ${styles.header}`}>
                <span className={`arrow-wrapper ${styles.arrowWrapper}`}></span>
                <span className={`header-wrapper ${styles.headerWrapper}`}>
                  <span className={`header-text ${styles.headerText}`}>Social network</span>
                </span>
              </h3>
              <p className={`channel-description ${styles.channelDescription}`}>Facebook, Instagram, TikTok, Pinterest & Snapchat</p>
            </div>
          </div>
          <div className={`marketing-channel ${styles.marketingChannel}`}>
            <div className={`image-wrapper ${styles.imageWrapper}`}>
              <div className={`image-overflow ${styles.imageOverflow}`}></div>
              <Img className={`marketing-channel-image ${styles.marketingChannelImage}`} fixed={data.image.childImageSharp.fixed} alt={data.image.base.split(".")[0]} style={{maxWidth: '240px', width: '100%'}} />
            </div>
            <div className={styles.content}>
              <h3 className={`area-header arrow-header arrow-header-2 ${styles.header}`}>
                <span className={`arrow-wrapper ${styles.arrowWrapper}`}></span>
                <span className={`header-wrapper ${styles.headerWrapper}`}>
                  <span className={`header-text ${styles.headerText}`}>Business Essentials</span>
                </span>
              </h3>
              <p className={`channel-description ${styles.channelDescription}`}>Google PPC Suite, Youtube Ads & Conversion Rate Optimization</p>
            </div>
          </div>
          <div className={`marketing-channel ${styles.marketingChannel}`}>
            <div className={`image-wrapper ${styles.imageWrapper}`}>
              <div className={`image-overflow ${styles.imageOverflow}`}></div>
              <img className={`marketing-channel-image ${styles.marketingChannelImage}`} src={lookingGif} alt="Looking around gif" />
            </div>
            <div className={styles.content}>
              <h3 className={`area-header arrow-header arrow-header-3 ${styles.header}`}>
                <span className={`arrow-wrapper ${styles.arrowWrapper}`}></span>
                <span className={`header-wrapper ${styles.headerWrapper}`}>
                  <span className={`header-text ${styles.headerText}`}>Native Advertising</span>
                </span>
              </h3>
              <p className={`channel-description ${styles.channelDescription}`}>Content Recommendations & In-feed Native Ads</p>
            </div>
          </div>
          <div className={`marketing-channel ${styles.marketingChannel}`}>
            <div className={`image-wrapper ${styles.imageWrapper}`}>
              <div className={`image-overflow ${styles.imageOverflow}`}></div>
              <img className={`marketing-channel-image ${styles.marketingChannelImage}`} src={babyThrowingMoneyGif} alt="Baby throwing money" />
            </div>
            <div className={styles.content}>
              <h3 className={`area-header arrow-header arrow-header-4 ${styles.header}`}>
                <span className={`arrow-wrapper ${styles.arrowWrapper}`}></span>
                <span className={`header-wrapper ${styles.headerWrapper}`}>
                  <span className={`header-text ${styles.headerText}`}>Your Database</span>
                </span>
              </h3>
              <p className={`channel-description ${styles.channelDescription}`}>SMS & Email Marketing</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MarketingChannelSection
