import React, { useEffect, useRef } from 'react'
import { gsap, TimelineLite  } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useStaticQuery, graphql } from "gatsby"
import { v4 as uuidv4 } from 'uuid'
import useViewport from "../useViewport"
import styles from "./customerBrands.module.scss"
import Img from "gatsby-image"

const CustomerBrands = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file( relativePath: { eq: "logos.webp" } ) {
        id
        base
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logos: allFile( filter: { relativeDirectory: { eq: "customerBrands" } } ) {
        nodes {
          id
          base
          childImageSharp {
            fixed(height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)

  const { width } = useViewport()
  const logosSection = useRef(null)
  
  useEffect(() => {
    if (width > 450) {
      const topLogos = logosSection.current.querySelectorAll('.logo.top-logo')
      const bottomLogos = logosSection.current.querySelectorAll('.logo.bottom-logo')
      const leftLogos = logosSection.current.querySelectorAll('.logo.left-logo')
      const rightLogos = logosSection.current.querySelectorAll('.logo.right-logo')

      gsap.registerPlugin(ScrollTrigger, TimelineLite);
      
      let tl = new TimelineLite ({
        scrollTrigger: {
          trigger: "#customer-brands",
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none reverse",
          scrub: true,
        },
        ease: "none",
      });
      
      tl.fromTo(topLogos, { y: -200 }, { y: 200 })
        .fromTo(bottomLogos, { y: -300 }, { y: 100 }, "0")
        .fromTo(leftLogos, { y: -200 }, { y: 200 }, "0")
        .fromTo(rightLogos, { y: -300 }, { y: 200 }, "0");
    }
  })

  return (
    <section ref={logosSection} id="customer-brands" className={styles.customerBrandsSection}>
      <div className={`${styles.customerBrandsSectionWrapper} container container-wrapper`}>
        <div className={`${styles.logosContainer} ${styles.logosContainerTop}`}>
          {data.logos.nodes.slice(0, 3).map(logo => (
            <Img className={`${styles.logo} logo top-logo`} key={uuidv4()} fixed={logo.childImageSharp.fixed} alt={logo.base.split(".")[0]} style={{ maxWidth: '210px', filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) grayscale(1)" }} imgStyle={{ objectFit: 'contain' }} />
          ))}
        </div>

        <p className={`section-header ${styles.sectionHeader}`}>We help direct to consumer brands acquire customers in paid media channels on a pay-per-result model.</p>
        <div className={`${styles.logosContainer} ${styles.logosContainerBottom}`}>
          {data.logos.nodes.slice(-3).map(logo => (
            <Img className={`${styles.logo} logo bottom-logo`} key={uuidv4()} fixed={logo.childImageSharp.fixed} alt={logo.base.split(".")[0]} style={{ maxWidth: '210px', filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) grayscale(1)" }} imgStyle={{ objectFit: 'contain' }} />
          ))}
        </div>
        {width > 450 &&
          <>
            <div className={`${styles.logosContainer} ${styles.logosContainerLeft}`}>
              {data.logos.nodes.slice(3, 5).map(logo => (
                <Img className={`${styles.logo} logo left-logo`} key={uuidv4()} fixed={logo.childImageSharp.fixed} alt={logo.base.split(".")[0]} style={{ maxWidth: '210px', filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) grayscale(1)" }} imgStyle={{ objectFit: 'contain' }} />
                ))}
            </div>
            <div className={`${styles.logosContainer} ${styles.logosContainerRight}`}>
              {data.logos.nodes.slice(5, 6).map(logo => (
                <Img className={`${styles.logo} logo right-logo`} key={uuidv4()} fixed={logo.childImageSharp.fixed} alt={logo.base.split(".")[0]} style={{ maxWidth: '210px', filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) grayscale(1)" }} imgStyle={{ objectFit: 'contain' }} />
                ))}
            </div>
          </>
        }
      </div>
    </section>
  )
}

export default CustomerBrands