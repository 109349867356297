import React, { useRef, useEffect } from "react"
import { gsap, TimelineLite  } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { useStaticQuery, graphql } from "gatsby"
import YoutubeVideo from "../youtubeVideo/youtubeVideo"
import styles from "./aboutUs.module.scss"
import 'swiper/swiper.scss'
gsap.registerPlugin(CSSRulePlugin, ScrollTrigger, TimelineLite);

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "about-us/hades-media-about-us.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
        base
      }
    }
  `)

  const aboutUsSection = useRef(null);
  
  useEffect(() => {
    const animatedUnderlineText = aboutUsSection.current.children[0].querySelector(".animated-underline");

    let tl = new TimelineLite ({
      scrollTrigger: {
        trigger: animatedUnderlineText,
        toggleActions: 'play none none none',
        start: 'top 80%',
      },
      ease: "power4.out"
    });

    tl.from(animatedUnderlineText, 1, {opacity: 0})
      .to(animatedUnderlineText, 2, {backgroundSize: "100% 2px"});
  })

  return (
    <section id="about-us" className={`${styles.aboutUsSection} about-us-section`} ref={aboutUsSection}>
      <div className={`${styles.sectionContainer} container`}>
        <div className={styles.sectionWrapper}>
          <div className={styles.contentArea}>
            <div className={styles.title}><h2 className={styles.text}>Who are we?</h2><span className={styles.line}></span></div>
            <p className={`${styles.content}`}>Hades has been known as the giver of wealth in ancient greek history.</p>
            <span className={`${styles.contentHelper} animated-underline`}>It's back now.</span>
          </div>

          <div className={`${styles.videoAreaWrapper}`}>
            <YoutubeVideo videoId={'oMrPfACyE_o'} image={data.image} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
