import React, { useRef, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss';
import styles from "./feedback.module.scss"
import FeedbackItem from "./feedbackItem"
import useViewport from "../useViewport"

const Feedback = () => {
  const data = useStaticQuery(graphql`
    query {
      sunImage: file(relativePath: { eq: "feedback/sun-365-feedback-hades-media.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
        base
      }
      aliveImage: file(relativePath: { eq: "feedback/alive-85-feedback-hades-media.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
        base
      }
    }
  `)

  const feedbacksData = [
    {
      name: 'Vince | Alive85',
      quote: 'We really enjoy working with Hades Media. These guys are super professional when it comes to digital advertising. They always make the most out of the budget. And they are never limited by channels.',
      image: data.aliveImage,
      videoId: 'aw-M11EPa8I'
    },
    {
      name: 'Linas | SUN365',
      quote: 'They’re very creative. They always bring new ideas to the table. Secondly, they create very effective digital marketing strategies. And thirdly, they’re very passionate about what they do.',
      image: data.sunImage,
      videoId: 'xyJ21J4YtY4'
    }
  ]

  const [currentSlide, setCurrentSlide] = useState(0);
  const { width } = useViewport()

  return (
    <section id="feedback" className={styles.feedback}>
      <div className={`${styles.headerWrapper} container`}>
        <h2 className={`section-header ${styles.sectionHeader}`}>At Hades, we believe there’s something much better than the fixed compensation model.</h2>
        <p className={styles.content}>Our monthly fee is there to only cover the running costs. You know, to keep the lights & macs on in our office. The rest of our payment depends on performance - we don’t make any money unless you do.</p>
        {/* {feedbacksData && (
          <div className={`${styles.navigationButtons} navigation-buttons`}>
            <span className={`${styles.navigationNumber} navigation-number`}>{`${currentSlide + 1}/${feedbacksData.length}`}</span>
            <div className={`${styles.buttonsWrapper} buttons-wrapper`}>
            </div>
          </div>
        )} */}
      </div>
      {feedbacksData && (
        <div className={`${styles.slideContainer} container container-wrapper`}>
          <div className={`${styles.buttonsContainer} container`}>
            <div id="swiper-next" className={`${styles.swiperButton} ${styles.next} swiper-button-next primary`}></div>
            <div id="swiper-prev" className={`${styles.swiperButton} ${styles.prev} swiper-button-prev primary`}></div>
          </div>
          <Swiper
            slideToClickedSlide={true}
            grabCursor={true}
            navigation={{
              nextEl: '#swiper-next',
              prevEl: '#swiper-prev',
            }}
            spaceBetween={width > 600 ? 30 : 15}
            onSlideChange={(swiper) => {setCurrentSlide(swiper.activeIndex)}}
            className={`${styles.sliderWrapper} container`} >

            {feedbacksData.map(item => (
              <SwiperSlide className={`${styles.item}`}>
                <FeedbackItem videoId={item.videoId} name={item.name} quote={item.quote} image={item.image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </section>
  )
}

export default Feedback
