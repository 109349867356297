import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import YouTube from "react-youtube"
import Img from "gatsby-image"
import styles from "./youtubeVideo.module.scss"
import 'swiper/swiper.scss'

const YoutubeVideo = ({videoId, image, name, quote}) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "about-us/hades-media-about-us-video.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
        base
      }
    }
  `)

  const [videoHidden, setVideoHidden] = useState(true)
  const [videoShown, setVideoShown] = useState(false)
  const [videoTime, setVideoTime] = useState(0)

  const renderVideo = () => {
    let opts = {
      width: '100%',
      className: 'video',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        color: 'white',
        autoplay: 1,
        rel: 0,
        start: videoTime,
      }
    };

    return <YouTube videoId={videoId}
                    opts={opts}
                    onPlay={() => videoPlayHandler()}
                    onEnd={() => videoEndHandler()}
                    onReady={(event) => videoReadyHandler(event)} />
  }

  const videoPlayHandler = () => {
    setVideoShown(false)
    setVideoHidden(false)
  }

  const videoEndHandler = () => {
    setVideoShown(true)
    setVideoHidden(true)
    setVideoTime(0)
  }

  const videoReadyHandler = (event) => {
    event.target.mute()
    event.target.playVideo()
    event.target.unMute()
  }

  const renderPlayText = () => {
    let text = 'play';
    videoShown && (text = 'play again')

    return text
  }

  return (
    <div className={`${styles.videoWrapper}`}>
      {videoHidden ? (
        <div className={styles.image}>
          <div className={styles.playWrapper} onClick={() => videoPlayHandler()}>
            <svg className={styles.playIcon} width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="#1A191B"/>
            </svg>
            <span className={styles.playText}>{renderPlayText()}</span>
          </div>
          <Img fluid={image.childImageSharp.fluid} imgStyle={{ objectFit: 'cover' }} style={{ height: '100%' }} alt={image.base.split(".")[0]} />
        </div>
        ) : renderVideo()
      }
    </div>
  )
}

export default YoutubeVideo
