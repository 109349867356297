import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import HoverIcons from "../../images/head-hover.inline.svg"
import ContactButton from "../contactButton"
import styles from "./heroSection.module.scss"

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    query {
      hadesHead: file(relativePath: { eq: "hades-head.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
    }
  `)

  return (
    <section className={styles.heroSection}>
      <div className="container">
        <div className={styles.heroSectionWrapper}>
          <div className={styles.headImage}>
            <Img fluid={data.hadesHead.childImageSharp.fluid} alt="Hades Media Logo" />
              <div className={styles.headHoverImage}>
                <HoverIcons />
              </div>
          </div>
          <div className={styles.heroContent}>
            <div className={styles.siteTitle}><span className={styles.text}>Hades media</span><span className={styles.line}></span></div>
            <h1 className={`${styles.pageHeader}`}>Facebook isn’t all that there is to scaling <span style={{whiteSpace: 'nowrap'}}>e-commerce.</span> Meet Hades.</h1>
            <ContactButton classes="outline" title="Schedule a Call" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
