import React, { useRef, useEffect, useState } from 'react'
import { gsap, TimelineLite  } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useViewport from "../useViewport"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./budgetSection.module.scss"
import Img from "gatsby-image"

const BudgetSection = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file( relativePath: { eq: "budgetSectionImages/hand.png" } ) {
        id
        base
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const budgetSection = useRef(null);
  const finger = useRef(null);
  const { width } = useViewport();
  
  gsap.registerPlugin(ScrollTrigger, TimelineLite);

  const rotateFinger = (angle) => {
    const fingerImage = budgetSection.current.children[0].querySelector('.section-image-wrapper .finger-image');
    (width > 769) && gsap.to(fingerImage, 1, { rotate: angle });
  };

  useEffect(() => {
    const fingerImageWrapper = budgetSection.current.children[0].querySelector('.section-image-wrapper');
    const fingerImage = budgetSection.current.children[0].querySelector('.section-image-wrapper .finger-image');
    const sectionHeader = budgetSection.current.children[0].querySelector('.section-header');
    const globalAreasGroup = budgetSection.current.children[0].querySelector('.global-areas');
    const globalAreas = budgetSection.current.children[0].querySelectorAll('.global-area');

    gsap.from(globalAreas, {
      scrollTrigger: {
        trigger: globalAreasGroup,
        toggleActions: 'play none none none',
        start: 'top 80%',
      },
      opacity: 0,
      x: -40, 
      stagger: .5, 
      autoAlpha: 0,
      duration: 1
    });

    let tl = new TimelineLite ({
      scrollTrigger: {
        trigger: sectionHeader,
        toggleActions: 'play none none none',
        start: 'top 80%'
      }
    });

    if (width > 769) {

      tl.from(sectionHeader, 1.5, { opacity: 0, x: 50, ease: "power2.out", autoAlpha: 0 })
      .fromTo(fingerImageWrapper, 1.5,
      {
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        webkitClipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
      },
      {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "expo.out"
      }, "-=1.25")
      .from(fingerImage, 1.5, { opacity: 0, scale: .9, x: 30, y: -20, autoAlpha: 0 }, "-=1.25");
    } else {
      tl.from(sectionHeader, 1.5, { opacity: 0, y: 50, ease: "power2.out", autoAlpha: 0 });

      tl.fromTo(fingerImageWrapper, 1.5,
        {
          clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
          webkitClipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        },
        {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          ease: "expo.out"
        }, "-=1.25")
        .from(fingerImage, 1.5, { opacity: 0, scale: .9, x: 30, y: -20, autoAlpha: 0 }, "-=1.25");
    }
  })

  return (
    <section id="experience" className={styles.budgetSection} ref={budgetSection} >
      <div className="container">
        <div className={styles.budgetSectionWrapper}>
          <div className={`${styles.sectionHeaderWrapper}`}>
            <h2 className={`section-header ${styles.sectionHeader}`}>We've managed advertising budgets globally</h2>
          </div>
          <div className={`${styles.sectionImageWrapper} section-image-wrapper`}>
            <Img className={`${styles.fingerImage} finger-image`} fluid={data.image.childImageSharp.fluid} alt={data.image.base.split(".")[0]} ref={finger} />
          </div>
          <div className={`${styles.globalAreas} global-areas`}>
            <div className={`${styles.area} global-area`} onMouseEnter={() => rotateFinger(8)}>
              <div className={styles.areaContent}>
                <h4 className={styles.areaTitle}>€0.25M</h4>
                <h3 className={`area-header ${styles.areaLocation}`}>Europe</h3>
              </div>
            </div>
            <div className={`${styles.area} global-area`} onMouseEnter={() => rotateFinger(-10)}>
              <div className={styles.areaContent}>
                <h4 className={styles.areaTitle}>US$6M</h4>
                <h3 className={`area-header ${styles.areaLocation}`}>United States</h3>
              </div>
            </div>
            <div className={`${styles.area} global-area`} onMouseEnter={() => rotateFinger(-50)}>
              <div className={styles.areaContent}>
                <h4 className={styles.areaTitle}>AU$25M</h4>
                <h3 className={`area-header ${styles.areaLocation}`}>Australia</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BudgetSection
