import React, { useRef, useEffect } from 'react'
import { gsap, TimelineLite  } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import useViewport from "../useViewport"
import styles from "./descriptionSection.module.scss"
gsap.registerPlugin(CSSRulePlugin, ScrollTrigger, TimelineLite);

const DescriptionSection = () => {
  const data = useStaticQuery(graphql`
    query Images {
      images: allFile( filter: { relativeDirectory: { eq: "descriptionSectionImages" } } ) {
        nodes {
          id
          base
          childImageSharp {
            fixed(height: 75) {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  
  const descriptionSection = useRef(null);
  const { width } = useViewport()

  
  useEffect(() => {
    const header = descriptionSection.current.children[0].querySelector(".underline-header");
    const headerLine = descriptionSection.current.children[0].querySelector(".animated-underline");
    const images = descriptionSection.current.children[0].querySelectorAll(".description-image");

    let tl = new TimelineLite ({
      scrollTrigger: {
        trigger: header,
        toggleActions: 'play none none none',
        start: 'top 80%',
      },
      ease: "power4.out"
    });

    tl.to(headerLine, 2, {backgroundSize: "100% 2px"})
      .from(images, .9, {opacity: 0, y: 40, stagger: .3}, "-=1");
  })

  const renderLogos = () => {
    const numOfLogos = width < 600 ? data.images.nodes.filter((node, index) => index !== 1) : data.images.nodes;

    return (
      numOfLogos.map(image => (
        <Img 
          key={image.id} 
          className={`description-image`}
          fixed={image.childImageSharp.fixed} 
          alt={image.base.split(".")[0]} 
          style={{ margin: "0 40px 40px 0", height: "65px"}} 
          imgStyle={{ objectFit: "contain" }} 
          />
      ))
    )
  }

  return (
    <section id="about" className={`${styles.descriptionSection} description-section`} ref={descriptionSection}>
      <div className="container">
        <div className={`${styles.descriptionSectionWrapper} description-section-wrapper`}>
          <div className={styles.descriptionSectionContent}>
            <p className={`${styles.description} description`}>Get traffic through Facebook, Instagram, TikTok, Google, Snapchat, Youtube, and even billboards. This omni channel approach has served consistently profitable ROI for our clients.</p>
            <h3 className={`${styles.underlineHeader} underline-header`}>
              <span className={`animated-underline`}>Our team has worked with brands you’ve actually heard about.</span>
            </h3>
            <div className={`${styles.imagesWrapper} images-wrapper`}>{renderLogos()}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DescriptionSection
