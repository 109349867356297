import React from "react"
import DescriptionSection from "../components/descriptionSection/descriptionSection"
import HeroSection from "../components/heroSection/heroSection";
import BudgetSection from "../components/budgetSection/budgetSection";
import MarketingChannelSection from "../components/marketingChannelSection/marketingChannelSection";
import CustomerBrands from "../components/customerBrands/customerBrands";
import ContactSection from "../components/contactSection/contactSection";
import Workflow from "../components/workflow/workflow";
import CaseStudies from "../components/caseStudies/caseStudies";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Feedback from "../components/feedback/feedback";
import AboutUs from "../components/aboutUs/aboutUs";

const IndexPage = () => {

  return (
    <Layout title="Home" >
      <SEO title="Home" />
      <HeroSection />
      <DescriptionSection />
      <BudgetSection />
      <MarketingChannelSection />
      <Workflow />
      <CustomerBrands />
      <CaseStudies />
      <AboutUs />
      <Feedback />
      <ContactSection />
    </Layout>
  )
}

export default IndexPage
