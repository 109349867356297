import React from 'react'
import styles from "./contactSection.module.scss"
import ContactButton from '../contactButton'

const ContactSection = () => {

  return (
    <section id="contact" className={styles.contactSection}>
      <div className="container">
        <div className={styles.contactSectionWrapper}>
          <p className={`section-header ${styles.sectionHeader}`}>Increase your revenue without compromising profitability. Try Hades.</p>
          <ContactButton title="Meet Hades" />
        </div>
      </div>
    </section>
  )
}

export default ContactSection
